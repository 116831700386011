import axios from "axios"
import router from "../router/index";
import { Message, Notification } from "element-ui";

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    405: '请求方法不被允许。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请联系管理员。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

const instance = axios.create({
    baseURL: 'https://web.water.zsyxlife.cn/',
    timeout: 50000
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // console.log('config', config)

    if (sessionStorage.getItem('token')) {
        config.headers['Authorization'] = sessionStorage.getItem('token')
    }
    return config;

}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    if (response.headers['authorization']) {
        sessionStorage.setItem('token', response.headers['authorization'])
    }
    if (response.data.code && response.data.code !== 200) {
        Message({
            type: 'error',
            message: response.data.msg,
            showClose: true
        })
    }
    if (response.data.msg === '无效token') {
        router.replace('/login')
    }
    return response;
}, function (error) {
    if (error.response.status === 401) {
        sessionStorage.removeItem('token')
        router.replace('/login')
    }
    Notification({
        title: '请求失败',
        message: codeMessage[error.response.status || 404],
        duration: 3000,
        type: 'error'
    })
    if (error.response.message) {
        Message({
            type: 'error',
            message: error.response.message,
            showClose: true
        })
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

export function http(url, method = 'GET', data = null, params = null) {
    return instance({url, method, data, params})
}


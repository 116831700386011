import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
 
import axios from 'axios';
import filterArray from './utils/dist/filter'
Vue.prototype.$filterArray = filterArray;

// 设置全局的 baseURL
// axios.defaults.baseURL = '/api';

// // 将 axios 实例挂载到 Vue 原型上
// Vue.prototype.$http = axios;

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

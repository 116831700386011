<template>
  <el-container class="layout-container">
    <el-aside :width="isCollapse ? '60px' : '220px'" style="background-color: #FFFFFF; " text-color="#999">
      <div class="el-aside-img">
        <img src="../img/biao.jpg" alt="">
      </div>
      <el-scrollbar class="menu">
        <el-menu :collapse="isCollapse" collapse-transition :default-openeds="activeMenu">
          <el-submenu :index="`${index}`" v-for="(item,index) in list">
            <template slot="title">
              <i :class="item.icon"></i>
              {{ item.menu_name }}
            </template>
            <el-submenu v-if="items.children" :index="`${index}-${indexs}`" v-for="(items,indexs) in item.children"
                        :key="indexs">
              <template slot="title">
                <router-link :to="items.query ? items.query : ''" tag="div">
                  {{ items.meta.title }}
                </router-link>
              </template>
              <el-menu-item v-for="(i,indexes) in items.children" :index="`${index}-${indexs}-${indexes}`">
                <router-link :to="i.query ? i.query : ''" tag="div" class="li">
                  {{ i.meta.title }}
                </router-link>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="`${index}-${indexs}`">
              <template slot="title">
                <router-link :to="items.query ? items.query : ''" tag="div">
                  {{ items.meta.title }}
                </router-link>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-scrollbar>

    </el-aside>

    <el-container>
      <el-header style="height: 90px;">
        <div class="el-header-top">
          <ul class="el-con-ul">
            <li style="margin-right: 20px;" @click="isCollapse = !isCollapse;">
              <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
            </li>
            <li @click="refresh">
              <i class="el-icon-refresh-right"></i>
            </li>
            <!--              <li><i class="el-icon-price-tag"></i></li>-->
          </ul>
          <ul class="el-con-ul-right">
            <!--              <li> <i class="el-icon-s-finance"></i><span>数据大屏</span></li>-->
            <li>
              <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                  admin<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="logout">
                    退出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>


        <!-- <div class="el-header-bottom">
          <div class="el-header-bottom-left">
            <p><i class="el-icon-house"></i></p>
          </div>
          <el-dropdown>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>关闭当前标签页</el-dropdown-item>
              <el-dropdown-item>关闭其他标签页</el-dropdown-item>
              <el-dropdown-item>关闭全部标签页</el-dropdown-item>

            </el-dropdown-menu>
            <i class="el-icon-arrow-down"></i>
          </el-dropdown>
        </div> -->
      </el-header>

      <el-main style="background-color: #f2f2f2;height: 100vh;">
        <router-view></router-view>
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
        >
          <span>是否确认要退出登录？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleLogout">确 定</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {getCebianlan} from '@/http/api'

export default {
  data() {
    return {
      list: [],
      isCollapse: false,
      dialogVisible: false,
      activeMenu: []
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') {
        this.dialogVisible = true
      }
    },
    handleLogout() {
      this.$store.commit('clearBack')
      this.$router.push('/login')
      this.dialogVisible = false
    },
    //获取侧边栏
    getcaidan() {
      getCebianlan().then(res => {
        this.list = res.data.data
      })
    },
    refresh() {
      location.reload()
    }
  },
  created() {
    this.getcaidan()
    if (['/index/index', '/index/dashboard'].indexOf(location.pathname) !== -1) {
      this.activeMenu = ['0']
    } else if (location.pathname === '/index') {
      window.location.href = "/index/dashboard"
    }
  }
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}

.layout-container {
  height: 100vh;
}

//.el-header {
//  background-color: white;
//  height: 90px;
//}
//
//.el-aside {
//  color: #333;
//  height: 100vh;
//  background-color: white !important;
//  position: fixed;
//  top: 0;
//  left: 0;
//  overflow-x: hidden;
//}
//
.el-aside-img {
  width: 220px;
  height: 90px;
  border-bottom: solid .5px #eee;
  border-right: solid .5px #eee;
  background-color: #fff !important;

}

//
.el-aside-img img {
  width: 90px;
  height: 85px;
  margin-top: 3px;
  margin-left: 25%;
  /* margin: 0 auto; */
  vertical-align: middle;
  /* text-align: center; */
  border: none;
  display: inline-block;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15)
}

//
//.el-menu {
//  width: 220px;
//  height: 1000px;
//  overflow: hidden;
//  position: fixed;
//  top: 90px;
//  overflow-y: auto;
//}
//
//.el-menu::-webkit-scrollbar {
//  display: none;
//}
//
.el-header-top {
  height: 50px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid .5px #eee;
  //padding: 0 10px;
  box-sizing: border-box;
}

.el-header-top .el-con-ul {
  width: 188px;
  display: flex;
  align-items: center;
}

.el-header-top .el-con-ul li {
  font-size: 20px;

}

.el-header-top .el-con-ul-right {
  width: 300px;
  padding-left: -40px;
  /* border: solid 1px; */
  display: flex;
  justify-content: space-around;
  align-items: center;

}

//
//.el-con-ul-right i {
//  font-size: 20px;
//}
//
//.el-con-ul-right span {
//  font-size: 14px;
//}
//
//.el-container {
//  width: 90%;
//  position: fixed;
//  top: 0;
//  left: 200px;
//}
//
//.el-header-bottom {
//  height: 30px;
//  /* border-bottom: solid 1px; */
//  display: flex;
//  justify-content: space-between;
//}
//
//.el-header-bottom-left {
//  width: 40px;
//  height: 30px;
//  background-color: #f6f6f6;
//}
//
//.el-header-bottom-left p {
//  text-align: center;
//  cursor: pointer;
//}
//.li{
//  color: black;
//}
</style>
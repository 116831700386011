// import { isArray } from "core-js/core/array";
//过滤空值
function dealObjectValue(obj) {
    var param = {};
    if (obj === null || obj === undefined || obj === "" || obj === []) return param;
    for (var key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "" && obj[key].length !== 0) {
            param[key] = obj[key];
        }
    }
    return param;
};

export default {
    dealObjectValue
}

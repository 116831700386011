import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token'),
    userInfo: sessionStorage.getItem('userInfo')
  },
  getters: {},
  mutations: {
    withUserInfo(state, da){
      state.token = da.token
    },
    withToken(state, data){
      state.token = data.token
      sessionStorage.setItem('token', data.token);
    },
    clearBack(state){
      state.token = ""
      sessionStorage.removeItem('token')
    }
  },
  actions: {},
  modules: {},
});

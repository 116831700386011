import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import IndexView from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path:'/',
    redirect:'/index/dashboard',
    meta: {
      title: '首页'
    }
  },
  {
    path: "/login",
    name: "login",
    component:()=>import('../views/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: "/index",
    name: "index",
    component: IndexView,
    meta: {
      title: '管理后台'
    },
    children:[
      {
        path:'shou',
        name:'shou',
        component:()=>import('../views/shebei/shou/shou.vue')
      }, 
      {
        path:'dingzhi',
        name:'dingzhi',
        component:()=>import('../views/shebei/shou/dingzhi.vue')
      }, 
      {
        path:'shengyin',
        name:'shengyin',
        component:()=>import('../views/shebei/shou/shengyin.vue')
      }, 
      {
        path:'tongyong',
        name:'tongyong',
        component:()=>import('../views/shebei/shou/tongyong.vue')
      }, 
      {
        path:'zhi',
        name:'zhi',
        component:()=>import('../views/shebei/jiayong/zhi.vue')
      }, 
      {
        path:'lvxin',
        name:'lvxin',
        component:()=>import('../views/shebei/jiayong/lvxin.vue')
      }, 
      {
        path:'bofang',
        name:'bofang',
        component:()=>import('../views/shebei/jiayong/bofang.vue')
      },  
      {
        path:'jiqizhuangtai',
        name:'jiqizhuangtai',
        component:()=>import('../views/shebei/jiayong/jiqizhuangtai.vue')
      }, 
      {
        path:'jiajichushezhi',
        name:'jiajichushezhi',
        component:()=>import('../views/shebei/jiayong/jiajichushezhi.vue')
      },  
      {
        path:'xufei',
        name:'xufei',
        component:()=>import('../views/shebei/jiayong/xufei.vue')
      },
      {
        path:'erweima',
        name:'erweima',
        component:()=>import('../views/shebei/jiayong/erweima.vue')
      },    
      {
        path:'simlist',
        name:'simlist',
        component:()=>import('../views/shebei/SIM/SIMlist.vue')
      },
      {
        path:'simdan',
        name:'simdan',
        component:()=>import('../views/shebei/SIM/SIMdan.vue')
      },  
      {
        path:"shebeitianjia",
        name:"shebeitianjia",
        component:()=>import('../views/shebei/shebeitianjia.vue')
      },
      {
        path:"kongzhiban",
        name:"kongzhiban",
        component:()=>import('../views/shebei/kongzhiban.vue')
      },     
      {
        path:'she',
        name:'she',
        component:()=>import('../views/shebei/shou/she.vue')
      },
      {
        path:"caidanlist",
        name:"caidanlist",
        component:()=>import('../views/caidan/caidanlist.vue')
      },
      {
        path:"xiaofeishuju",
        name:"xiaofeishuju",
        component:()=>import('../views/shuju/xiaofeishuju.vue')
      },
      {
        path:"chongzhijilu",
        name:"chongzhijilu",
        component:()=>import('../views/shuju/chongzhi.vue')
      },
      {
        path:"caozuo",
        name:"caozuo",
        component:()=>import('../views/shuju/caozuo.vue')
      },
      {
        path:"yujing",
        name:"yujing",
        component:()=>import('../views/shuju/yujing.vue')
      },
      {
        path:"yingli",
        name:"yingli",
        component:()=>import('../views/shuju/yingli.vue')
      },
      {
        path:"weixiu",
        name:"weixiu",
        component:()=>import('../views/shuju/weixiu.vue')
      },
      {
        path:"caozuorizhi",
        name:"caozuorizhi",
        component:()=>import('../views/shuju/caozuo.vue')
      },
      {
        path:"huiyuan",
        name:"huiyuan",
        component:()=>import('../views/huiyuan/huilist.vue')
      },
      
      {
        path:"huidengji",
        name:"huidengji",
        component:()=>import('../views/huiyuan/huidengji.vue')
      },
      {
        path:"guanli",
        name:"guanli",
        component:()=>import('../views/huiyuan/huiyuanka/guanli.vue')
      },
      {
        path:"chushou",
        name:"chushou",
        component:()=>import('../views/huiyuan/huiyuanka/chushou.vue')
      },
      {
        path:"jihuo",
        name:"jihuo",
        component:()=>import('../views/huiyuan/shiti/jihuo.vue')
      },
      {
        path:"tongji",
        name:"tongji",
        component:()=>import('../views/huiyuan/tongji.vue')
      },
      {
        path:"piciguanli",
        name:"piciguanli",
        component:()=>import('../views/huiyuan/shiti/piciguanli.vue')
      },
      {
        path:"chakan",
        name:"chakan",
        component:()=>import('../views/huiyuan/shiti/chakan.vue')
      },
      {
        path:"xiang",
        name:"xiang",
        component:()=>import('../views/huiyuan/xiang.vue')
      },
      {
        path:"biaoqian",
        name:"biaoqian",
        component:()=>import('../views/huiyuan/biaoqian.vue')
      },
      {
        path:"qinfenzu",
        name:"qinfenzu",
        component:()=>import('../views/huiyuan/qin/qinfenzu.vue')
      },
      {
        path:"qinlist",
        name:"qinlist",
        component:()=>import('../views/huiyuan/qin/qinlist.vue')
      },
      {
        path:"huodong",
        name:"huodong",
        component:()=>import('../views/yingxiao/huodong.vue')
      },
      {
        path:"faburenwu",
        name:"faburenwu",
        component:()=>import('../views/yingxiao/faburenwu.vue')
      },
      {
        path:"renwuliebiao",
        name:"renwuliebiao",
        component:()=>import('../views/yingxiao/renwuliebiao.vue')
      },
      {
        path:"lipinkaduihuan",
        name:"lipinkaduihuan",
        component:()=>import('../views/yingxiao/lipinkaduihuan.vue')
      },
      {
        path:"lipinka",
        name:"lipinka",
        component:()=>import('../views/yingxiao/lipinka.vue')
      },
      {
        path:"chengzhang",
        name:"chengzhang",
        component:()=>import('../views/yingxiao/chengzhang.vue')
      },
      {
        path:"yuangonglist",
        name:"yuangonglist",
        component:()=>import('../views/yuangong/yuangonglist.vue')
      },
      {
        path:"caozuo",
        name:"caozuo",
        component:()=>import('../views/yuangong/caozuo.vue')
      },
      {
        path:"tixianlist",
        name:"tixianlist",
        component:()=>import('../views/yuangong/tixianlist.vue')
      },
      // {
      //   path:"fenqu",
      //   name:"fenqu",
      //   component:()=>import('../views/yuangong/fenqu.vue')
      // },
      {
        path:"shebeifenqu",
        name:"shebeifenqu",
        component:()=>import('../views/yuangong/shebeifenqu.vue')
      },
      {
        path:"jiaoselist",
        name:"jiaoselist",
        component:()=>import('../views/yuangong/jiaoselist.vue')
      },
      {
        path:"shouquan",
        name:"shouquan",
        component:()=>import('../views/yuangong/shouquan.vue')
      },
      {
        path:"jichu",
        name:"jichu",
        component:()=>import('../views/yuangong/jichu.vue')
      },
      {
        path:"dengji",
        name:"dengji",
        component:()=>import('../views/qudao/dengji.vue')
      },
      {
        path:"fenxiaodingdan",
        name:"fenxiaodingdan",
        component:()=>import('../views/qudao/fenxiaodingdan.vue')
      },
      {
        path:"tixian",
        name:"tixian",
        component:()=>import('../views/qudao/tixian.vue')
      },
      {
        path:"jichushezhi",
        name:"jichushezhi",
        component:()=>import('../views/qudao/jichushezhi.vue')
      },
      {
        path:"fenxiaoguanli",
        name:"fenxiaoguanli",
        component:()=>import('../views/qudao/fenxiaoguanli.vue')
      },
      {
        path:"manchong",
        name:"manchong",
        component:()=>import('../views/shangcheng/manchong.vue')
      },
      {
        path:"kuaichong",
        name:"kuaichong",
        component:()=>import('../views/shangcheng/kuaichong.vue')
      },
      {
        path:"manzhifuguize",
        name:"manzhifuguize",
        component:()=>import('../views/shangcheng/manzhifuguize.vue')
      },
      {
        path:"kuaizhifu",
        name:"kuaizhifu",
        component:()=>import('../views/shangcheng/kuaizhifu.vue')
      },
      {
        path:"dianfeichongzhi",
        name:"dianfeichongzhi",
        component:()=>import('../views/shangcheng/dianfeichongzhi.vue')
      },
      {
        path:"diquguize",
        name:"diquguize",
        component:()=>import('../views/shangcheng/diquguize.vue')
      },
      {
        path:"dianfeizhifu",
        name:"dianfeizhifu",
        component:()=>import('../views/shangcheng/dianfeizhifu.vue')
      },
      {
        path:"dingdanfangshi",
        name:"dingdanfangshi",
        component:()=>import('../views/shangcheng/dingdanfangshi.vue')
      },
      {
        path:"dingdanchongzhi",
        name:"dingdanchongzhi",
        component:()=>import('../views/shangcheng/dingdanchongzhi.vue')
      },
      {
        path:"fangshi",
        name:"fangshi",
        component:()=>import('../views/xitong/tongzhitixing/fangshi.vue')
      },
      {
        path:"pingtaishezhi",
        name:"pingtaishezhi",
        component:()=>import('../views/xitong/pingtai.vue')
      },
      {
        path:"shezhi",
        name:"shezhi",
        component:()=>import('../views/xitong/tongzhitixing/shezhi.vue')
      },
      {
        path:"geren",
        name:"geren",
        component:()=>import('../views/xitong/geren.vue')
      },
      {
        path:"dashboard",
        name:"dashboard",
        component:()=>import('../views/statistic/dashboard.vue')
      },
      {
        path:"gonggaolist",
        name:"gonggaolist",
        component:()=>import('../views/xitong/tongzhitixing/gonggaolist.vue')
      },
      {
        path:"lun",
        name:"lun",
        component:()=>import('../views/xitong/lun.vue')
      },
      {
        path:"gerenzhongxin",
        name:"gerenzhongxin",
        component:()=>import('../views/xitong/gerenzhongxin.vue')
      },
      {
        path:"xitongzhifu",
        name:"xitongzhifu",
        component:()=>import('../views/xitong/zhifu.vue')
      },
      {
        path:"shangchuan",
        name:"shangchuan",
        component:()=>import('../views/shangchuan.vue')
      },
     
      
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import {http} from '../http/index'
import { Message } from 'element-ui'

export function getUserList(params = {})
{
    return http('api/user/index', 'GET', {}, params)
}
export function findUserById(params = {})
{
    return http('api/user/detail', 'GET', {}, params)
}
export function addUser(data = {})
{
    return http('api/user/new', 'POST', data)
}
export function editUser(data = {})
{
    return http('api/user/update', 'POST', data)
}
export function deleteUser(data = {})
{
    return http('api/user/delete', 'POST', data)
}
// 登录
export function getLogin(data){
    return http('api/login','POST',data)
}
//获取侧边栏
export function getCebianlan(params){
    return http('api/auth/getRouters','GET',{},params)
}
// 菜单
export function getcaidan(params){
    return http('api/menu/index','GET',{},params)
}
// 新增菜单
export function getxincaidan(data){
    return http('api/menu/new','POST',data)
}
// 删除菜单
export function getdelcaidan(data){
    return http('api/menu/delete','POST',data)
}
//获取菜单树
export function getcaidanshu(params){
    return http('api/role/tree','GET',{},params)
}
// 修改
export function getxiucaidan(data){
    return http('api/menu/update','POST',data)
}
// 获取角色列表
export function getjiaoselist(params){
    return http('api/role/index','GET',{},params)
}
//添加角色
export function addRolslist(data){
    return http('api/role/new','POST',data)
}
// 获取角色
export function getCaiRolslist(params){
    return http('api/role/menuTree','GET',{},params)
}
//删除角色
export function delRolslist(data){
    return http('api/role/delete','POST',data)
}

//编辑角色
export function editRolslist(data){
    return http('api/role/update','POST',data)
}

export function getBoardList(params){
    return http('api/board/index','GET', {}, params)
}
export function addBoard(data){
    return http('api/board/new','POST',data)
}

export function editBoard(data){
    return http('api/board/update','POST',data)
}

export function delBoard(data){
    return http('api/board/delete','POST',data)
}

//获取设备
export function getShelist(params){
    return http('api/dispenser/index','GET',{},params)
}
//删除设备
export function delShelist(data){
    return http('api/dispenser/delete','POST',data)
}
export function batchUpdate(data){
    return http('api/dispenser/batch_update','POST',data)
}
export function getWechat(data){
    return http('api/website/front/wechat','GET', {}, data)
}
export function saveWechat(data){
    return http('api/website/front/saveWechat','POST',data)
}
export function getSetupList(data){
    return http('api/website/front/index','GET', {}, data)
}
export function addSetup(data){
    return http('api/website/front/new','POST',data)
}

export function updateSetup(data){
    return http('api/website/front/update','POST',data)
}

export function delSetup(data){
    return http('api/website/front/delete','POST',data)
}

//添加设备
export function addShelist(data){
    return http('api/dispenser/new','POST',data)
}

export function updateCharging(data){
    return http('api/dispenser/update_charging','POST',data)
}

//批量导入设备
export function addBatchDevice(data){
    return http('api/dispenser/batchnew','POST',data)
    // return http('api/image/carousel/new','POST',data)// 添加轮播
}

//编辑设备
export function editShelist(data){
    return http('api/dispenser/update','POST',data)
}
//获取基础设置
export function getJichu(type=1){
    return http('api/equipment/setup/detail','GET',{},{type})
}
//确认基础设置
export function sureJichu(data){
    return http('api/equipment/setup/update','POST',data)
}
//获取计费列表
export function getJifeilist(params){
    return http('api/dispenser/charging/index','GET',{},params)
}
//删除计费列表
export function delJifeilist(params){
    return http('api/dispenser/charging/delete','POST',params)
}
//修改计费列表
export function revJifeilist(params){
    return http('api/dispenser/charging/update','POST',params)
}
//获取通用规则
export function getTongyonglist(params){
    return http('api/dispenser/charging/detail','GET',{},params)
}
//获取二维码
export function getErweima(da){
    return http('api/equipment/purifier/qrcode/'+da,'GET')
}
//获取净水器机器状态
export function getJingshuilist(params){
    return http('api/purifier/index','GET',{},params)
}
//获取家用电设置信息
export function getJiashezhi(type=2){
    return http('api/equipment/setup/detail','GET',{},{type})
}
//修改净水器
export function editJingshui(data){
    return http('api/purifier/update','POST',data)
}
//修改净水器基础设置状态
export function editSheJingshui(data){
    return http('api/equipment/setup/update','POST',data)
}
//添加计费
export function addJifeilist(data){
    return http('api/dispenser/charging/new','POST',data)
}
//获取sim列表
export function getSimlist(params){
    return http('api/equipment/sim/index','GET',{},params)
}
//添加SIM
export function simRecharge(params){
    return http('api/equipment/sim/recharge','POST',params)
}
//删除sim
export function delSimlist(params){
    return http('api/equipment/sim/delete','POST',params)
}
//修改SIM
export function revSimlist(params){
    return http('api/equipment/sim/update','POST',params)
}
//设备分区list
export function getShefen(){
    return http('api/equipment/partition/index','GET')
}

//获取会员列表
export function getHuilist(params){
    return http('api/member/index','GET',{},params)
}
//导出会员列表
export function getExportMemberList(data){
    return http('api/member/exportmemberlist','POST',data)
}


export function fetchHistoryGift(params){
    return http('api/market/gift/history','GET',{},params)
}

export function fetchWarmLog(params){
    return http('api/dispenser/warm','GET',{},params)
}

export function searchMember(params){
    return http('api/member/search','GET',{},params)
}

export function updateMember(data){
    return http('api/member/update','POST',data)
}

//获取用户标签
export function getBiaoqian(){
    return http('api/member/tag/index','GET')
}

export function findMemberById(id) {
    return http('api/member/info', 'GET', null, {id})
}

export function getBoard()
{
    return http('api/board/index');
}

//导入会员
export function getDaoru(data){
    return http('api/member/batch/new','POST',data)
}
//批量充值
export function getPiliang(data){
    return http('api/member/batch/topup','POST',data)
}

//获取会员等级
export function getHuiDengji(){
    return http('api/member/level/index','GET')
}

//新增会员等级
export function addHuiDengji(data){
    return http('api/member/level/new','POST',data)
}
//编辑会员等级
export function editHuiDengji(data){
    return http('api/member/level/update','POST',data)
}

//删除会员等级
export function delHuiDengji(data){
    return http('api/member/level/delete','POST',data)
}

//获取会员卡列表
export function getHuiyuanKa(params){
    return http('api/card/index','GET',{},params)
}

//添加会员卡
export function addHuiyuanKa(data){
    return http('api/card/new','POST',data)
}

//编辑会员卡
export function editHuiyuanKa(data){
    return http('api/card/update','POST',data)
}

//删除会员卡
export function delHuiyuanKa(data){
    return http('api/card/delete','POST',data)
}
//获取出售列表
export function getChushou(params){
    return http('api/member/record/card','GET',{},params)
}
//获取实体卡
export function getShitiKa(params){
    return http('api/card/physical/card','GET',{},params)
}

//获取批次列表
export function getPiCilist(params){
    return http('api/card/physical/batch','GET',{},params)
}

//新增批次
export function addPiCilist(data){
    return http('api/card/physical/new','POST',data)
}

//新增标签
export function addBiaoqian(data){
    return http('api/member/tag/new','POST',data)
}

//修改标签
export function editBiaoqian(data){
    return http('api/member/tag/update','POST',data)
}

//删除标签
export function delBiaoqian(data){
    return http('api/member/tag/delete','POST',data)
}
//获取充值记录明细
export function getChongzhi(params){
    return http('api/statistics/topup','GET',{},params)
}
export function fetchPurchases(params){
    return http('api/statistics/purchase','GET',{},params)
}
export function fetchDashboard(params){
    return http('api/statistics/dashboard','GET',{},params)
}
// 消费充值记录导出RechargeRecord
export function getExportConsumptionRecordsList(data){
    return http('api/statistics/exportconsumptionrecordslist','POST',data)
}
export function fetchDashboardOrder(params){
    return http('api/statistics/order','GET',{},params)
}
export function fetchDashboardStatistic(params){
    return http('api/statistics/statistic','GET',{},params)
}
//获取充值活动列表
export function getHuodong(params){
    return http('api/market/topup/index','GET',{},params)
}
//新增充值规则
export function addChongzhi(data){
    return http('api/market/topup/new','POST',data)
}

//修改充值规则
export function editChongzhi(data){
    return http('api/market/topup/update','POST',data)
}
//删除充值规则
export function delChongzhi(data){
    return http('api/market/topup/delete','POST',data)
}

//获取礼品卡列表
export function getLipinka(params){
    return http('api/market/gift/index','GET',{},params)
}
//新增礼品卡列表
export function addLipinka(data){
    return http('api/market/gift/new','POST',data)
}
//修改礼品卡列表
export function editLipinka(data){
    return http('api/market/gift/update','POST',data)
}
//删除礼品卡列表
export function delLipinka(data){
    return http('api/market/gift/delete','POST',data)
}
//发放礼品卡
export function faLipinka(data){
    return http('api/market/gift/issue','POST',data)
}
//获取任务管理
export function getRenwu(params){
    return http('api/market/task/index','GET',{},params)
}
//新增任务管理
export function addRenwu(data){
    return http('api/market/task/new','POST',data)
}
//删除任务管理
export function delRenwu(data){
    return http('api/market/task/delete','POST',data)
}
//修改任务列表
export function revRenwu(data){
    return http('api/market/task/update','POST',data)
}
//获取设备分区列表
export function getSheBeilist(params){
    return http('api/equipment/partition/index','GET',{},params)
}
//添加设备分区
export function addSheBeilist(data){
    return http('api/equipment/partition/new','POST',data)
}
//删除设备分区
export function delSheBeilist(data){
    return http('api/equipment/partition/delete','POST',data)
}
//修改设备分区
export function revSheBeilist(data){
    return http('api/equipment/partition/update','POST',data)
}
//获取授权额度
export function getShouquan(params){
    return http('api/user/balance/history','GET',{},params)
}
//获取分销商管理列表
export function getFenxiaolist(params){
    return http('api/distribution/index','GET',{},params)
}
//分销商管理-添加获取用户
// export function searchMember(params){
//     return http('api/member/search','GET',{},params)
// }
//添加分销商
export function addFenxiaolist(data){
    return http('api/distribution/new','POST',data)
}
//删除分销商
export function delFenxiaolist(data){
    return http('api/distribution/delete','POST',data)
}
//编辑分销商
export function revFenxiaolist(data){
    return http('api/distribution/update','POST',data)
}
//获取分销等级
export function getFenxiaodengji(params){
    return http('api/distribution/level/index','GET',{},params)
}
//添加分销等级
export function addFenxiaodengji(data){
    return http('api/distribution/level/new','POST',data)
}
//编辑分销等级
export function editFenxiaodengji(data){
    return http('api/distribution/level/update','POST',data)
}
//删除分销等级
export function delFenxiaodengji(data){
    return http('api/distribution/level/delete','POST',data)
}
//获取分销设置
export function getFenxiaoshezhi(type= 2){
    return http('api/distribution/setup','GET',{},{type})
}
//确认修改分销设置
export function sureFenxiaoshezhi(data){
    return http('api/distribution/setup/update','POST',data)
}
//获取慢充列表
export function getManchong(params){
    return http('api/market/virtual/index','GET',{},params)
}
export function fetchVirtualOrder(params){
    return http('api/market/virtual/order','GET',{},params)
}
export function refundVirtualOrder(data){
    return http('api/market/virtual/refund','POST', data)
}
export function finishVirtualOrder(data){
    return http('api/market/virtual/action','POST', data)
}
//添加慢充列表
export function addManchonglist(data){
    return http('api/market/virtual/new','POST',data)
}
//编辑慢充列表
export function editManchonglist(data){
    return http('api/market/virtual/update','POST',data)
}
//删除慢充列表
export function delManchonglist(data){
    return http('api/market/virtual/delete','POST',data)
}
//慢充支付规则
export function getZhifuguize(type=1){
    return http('api/market/virtual/cost/detail','GET',{},{type})
}

//确认慢充规则
export function sureManchonglist(data){
    return http('api/market/virtual/cost/update','POST',data)
}
//快充支付规则
export function getKuaiZhifuguize(type=2){
    return http('api/market/virtual/cost/detail','GET',{},{type})
}
//电费支付规则
export function getDianZhifuguize(type=3){
    return http('api/market/virtual/cost/detail','GET',{},{type})
}
//获取公告列表
export function getGonggao(params){
    return http('api/notice/announcement/index','GET',{},params)
}
//新增公告
export function addGonggao(data){
    return http('api/notice/announcement/new','POST',data)
}
//修改公告
export function revGonggao(data){
    return http('api/notice/announcement/update','POST',data)
}
//删除公告
export function delGonggao(data){
    return http('api/notice/announcement/delete','POST',data)
}
//获取轮播图
export function getLunbotu(params){
    return http('api/image/carousel/index','GET',{},params)
}
//添加轮播图
export function addLunbotu(data){
    return http('api/image/carousel/new','POST',data)
}
//删除轮播图
export function delLunbotu(data){
    return http('api/image/carousel/delete','POST',data)
}
//获取亲情账号列表
export function getQinqing(params){
    return http('api/member/affection/member','GET',{},params)
}
//获取亲情分类列表
export function getFenlei(params){
    return http('api/member/affection/index','GET',{},params)
}
//创建分类
export function addFenlei(data){
    return http('api/member/affection/new','POST',data)
}
//删除分类
export function delFenlei(data){
    return http('api/member/affection/delete','POST',data)
}
//编辑分类
export function editFenlei(data){
    return http('api/member/affection/update','POST',data)
}

//获取成长体系
export function getChengzhang(params){
    return http('api/member/growth/detail','GET',{},params)
}
//修改成长体系
export function editChengzhang(data){
    return http('api/member/growth/update','POST',data)
}

